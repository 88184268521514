import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import store from "store";
import { Subject } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

import { CoreConfigService } from "@core/services/config.service";
import { CommonService } from "app/services/common.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { DOCUMENT } from "@angular/common";
import { NgSelectConfig } from "@ng-select/ng-select";
import { TranslateService } from "@ngx-translate/core";
import { BrandStylingService } from "app/base/brand-styling/brand-styling.service";
import { MarketplaceService } from "app/marketplace/services/marketplace.service";
import { RingCentralService } from "app/admin/service/ringcentral.service";
import { decryptData } from "app/common/utils/utils";
import WebPhone from "ringcentral-web-phone/lib/src/index";
import { AuthService } from "app/base/auth/services/auth.service";
import { User } from "app/auth/models";

@Component({
	selector: "vertical-layout",
	templateUrl: "./vertical-layout.component.html",
	styleUrls: ["./vertical-layout.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class VerticalLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
	coreConfig: any;
	submenu: boolean;
	currentscreen: any;
	tempScreen: any;
	hoverClass: any;
	isAdminPortal = false;
	isInfraonAdmin = false;
	isSupportPortal = false;
	isSelfServicePortal = false;
	isMspPortal = false;
	public showSidebarDetails: any = {};
	// Private
	private _unsubscribeAll: Subject<any>;
	requesterData: any = {};
	ring_info: any = {};
	clientId: any;
	clientSecret: any;
	serverURL: any;
	jwt_token: any;
	sender_number: any;
	webPhone: any;
	primaryNumber: any;
	extension: any;
	is_call_disabled: boolean = false;
	sip_data: any;
	public currentUser: User;
	
	/**
	 * Constructor
	 *
	 * @param {CoreConfigService} _coreConfigService
	 */
	constructor(
		@Inject(DOCUMENT) private document: any,
		private _coreConfigService: CoreConfigService,
		private _elementRef: ElementRef,
		private _commonServices: CommonService,
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _coreSidebarService: CoreSidebarService,
		private _translateService: TranslateService,
		private ngSelectConfig: NgSelectConfig,
		private _brandStylingService: BrandStylingService,
		private _marketplaceService: MarketplaceService,
		private _ringCentral: RingCentralService,
		private _authService: AuthService,
	) {
		const notFoundText = this._translateService.instant("UI.k_no_items_found");
		//	console.log("Not found TXT", notFoundText);

		this.ngSelectConfig.notFoundText = notFoundText;
		// Set the private defaults
		this._unsubscribeAll = new Subject();
		this._commonServices.fluentMenu.subscribe((val) => {
			this.hoverClass = val;
			//console.log(this.hoverClass);
		});
		this._router.events.subscribe((event) => {
			if (event instanceof NavigationEnd) {
				const allPath = event.url.split("/");
				const mainPath = allPath[1];
				let redirectPath = "";
				if (allPath.length > 2) {
					redirectPath = allPath[2];
				}
				let accessType = store.get("accessType");
				if (mainPath === "admin" || redirectPath === "admin") {
					this._coreConfigService.setConfig({
						layout: {
							adminPortal: true,
							layoutStyle: "fixed",
							themeColor: 'default',
							navbar: {
								type: "fixed-top",
								hidden: redirectPath.split("?").includes("payment") ? true : false,
								backgroundColor: 'bg-card',
							},
							menu: {
								collapsed: false,
								hidden: redirectPath.split("?").includes("payment") ? true : false,
								logoBackgroundColor: 'bg-card',
							},
							footer: { hidden: true, type: "footer-static" },
						},
					});
					this.isAdminPortal = true;
					this.isInfraonAdmin = false;
					this.isSupportPortal = false;
					this.isSelfServicePortal = false;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("admin-portal");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("");

					// }else if(mainPath === 'self-service' || accessType== 2){
				} else if (mainPath === "self-service" || accessType == 2) {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: false,
							themeColor: 'default',
							menu: {
								hidden: true,
								logoBackgroundColor: 'bg-card',
							},
							navbar: {
								type: "fixed-top",
								backgroundColor: 'bg-card',
						 	},
							footer: { hidden: false, type: "footer-sticky" },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSupportPortal = false;
					this.isSelfServicePortal = true;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("self-service");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("");
				} else if (mainPath === "support") {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: true,
							themeColor: 'default',
							menu: { collapsed: true, logoBackgroundColor: 'bg-card' },
							navbar: { type: "fixed-top" },
							footer: { hidden: true, type: "footer-static", backgroundColor: 'bg-card' },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = true;
					this.isMspPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("support-portal");
					this._coreConfigService._mspSubject.next("");
				}else if (mainPath === "msp") {
					this._coreConfigService.setConfig({
						layout: {
							layoutStyle: "fixed",
							adminPortal: false,
							supportPortal: false,
							mspPortal:true,
							menu: { collapsed: true },
							navbar: { type: "fixed-top" },
							footer: { hidden: true, type: "footer-static" },
						},
					});
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = false;
					this.isMspPortal = true;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
					this._coreConfigService._mspSubject.next("msp-portal");
				}
				else {
					this._brandStylingService
					.loadOrganiationConfig({ config_type: "branding" })
					.pipe(
						map((response: any) => {
							if (response) {
								this._coreConfigService.setConfig({
									layout: {
										adminPortal: false,
										supportPortal: false,
										layoutStyle: response.branding.layout_style,
										borderStyle: response.branding.border_style,
										themeColor: response.branding.theme,
										navbar: {
											backgroundColor: response.branding.navbar,
											type: "fixed-top"
										},
										menu: {
											logoBackgroundColor: response.branding.logocontainer,
											collapsed: true,
											logo: response?.branding?.logo,
										},
										footer: { hidden: true, type: "footer-static" },
									},
								})
							}
						})).subscribe();
					// this._coreConfigService.setConfig({
					// 	layout: {
					// 		adminPortal: false,
					// 		supportPortal: false,
					// 		menu: { collapsed: true },
					// 		navbar: { type: "fixed-top" },
					// 		footer: { hidden: true, type: "footer-static" },
					// 	},
					// });
					this.isAdminPortal = false;
					this.isInfraonAdmin = false;
					this.isSelfServicePortal = false;
					this.isSupportPortal = false;
					this._coreConfigService._selfServiceSubject.next("");
					this._coreConfigService._adminSubject.next("");
					this._coreConfigService._supportSubject.next("");
				}
			}
		});
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (this.coreConfig.layout["adminPortal"]) {
				this.isAdminPortal = true;
				this.document.body.classList.remove("spaced-out-layout");
				this.document.body.classList.add(this.coreConfig.layout.layoutStyle + "-layout");
			} else {
				this.isAdminPortal = false;
			}
		});
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (this.coreConfig.layout["supportPortal"]) {
				this.isSupportPortal = true;
				this.document.body.classList.remove("spaced-out-layout");
				this.document.body.classList.add(this.coreConfig.layout.layoutStyle + "-layout");
			} else {
				this.isSupportPortal = false;
			}
		});
		this._authService.currentUser.subscribe((x) => (this.currentUser = x));
	}

	// Lifecycle Hooks
	// -----------------------------------------------------------------------------------------------------

	/**
	 * On init
	 */
	ngOnInit(): void {
		// Subscribe to config changes
		this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
			this.coreConfig = config;
			if (!this.coreConfig.layout?.navbar?.backgroundColor) {
				// this.coreConfig.layout.navbar.backgroundColor = this.coreConfig.layout.navbar?.backgroundColor;
				if (localStorage.getItem("navbar")) {
					this.coreConfig.layout.navbar.backgroundColor = localStorage.getItem("navbar");
				}
			}
		});
		this._commonServices.initCallApp.subscribe((key:Boolean) => {
			if(key){
				this.initCallApp();
			}
		})
		// this._coreConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
		// 	this.coreConfig = config;
		// 	let background = this.coreConfig.layout?.navbar?.backgroundColor;
		// 	console.log("background", background);
		// 	if (!background && background == "null") {
		// 		this.coreConfig.layout.navbar.backgroundColor = this.coreConfig.layout.navbar?.backgroundColor;
		// 	} else if (localStorage.getItem("navbar")) {
		// 		this.coreConfig.layout.navbar.backgroundColor = localStorage.getItem("navbar");
		// 	}
		// });
		this._commonServices.requester.subscribe((value: { req: any; reqProfile: any, webPhone: any, primaryNumber: any }) => {
			this.requesterData['requester'] = value?.req;
			this.requesterData['requesterProfile'] = value?.reqProfile;
			this.requesterData['webPhone'] = value?.webPhone;
			this.requesterData['primaryNumber'] = value?.primaryNumber
		});
	}
	ngAfterViewInit() {}
	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(undefined);
		this._unsubscribeAll.complete();
	}
	onHideGetInTouch() {
		// this.showSidebarDetails.getIntouch = true;
		this._coreSidebarService.getSidebarRegistry("get-in-touch").toggleOpen();
	}

	initRingCentral = async () => {
		await this._ringCentral.initRingCentral(this.clientId, this.clientSecret, this.serverURL);
		await this._ringCentral
		.getRingCentralInstance()
		.login({
			jwt: this.jwt_token,
		})
		.then(async (res) => {
			const webPhone = await this.onLoginSuccess(res);
			this.is_call_disabled = false
			this.webPhone = webPhone;
		})
		.catch((e) => {
			console.error(e.stack);
		});
	}

	onLoginSuccess = async (loginResponse) => {
		try {
		// Retrieve primary phone number
		this.primaryNumber = await this._ringCentral
		.getRingCentralInstance()
		.get("/restapi/v1.0/account/~/extension/~/phone-number")
		.then((res) => res.json())
		.then((res) => {
			let primary_no_list = res.records.filter((r) => r.primary === true);
			if (primary_no_list?.length > 0) {
			return res.records.filter((r) => r.primary === true)[0].phoneNumber;
			} else if (res.records?.length > 0) {
			return res.records[0].phoneNumber;
			}
		});

		// Register the WebPhone
		const response = await this._ringCentral
		.getRingCentralInstance()
		.post("/restapi/v1.0/client-info/sip-provision", {
			sipInfo: [{ transport: "WSS" }],
		});
		this.sip_data = await response.json();
		return await this.registerWebPhone(loginResponse, this.sip_data)
    } catch (error) {
		console.error("Error registering WebPhone:", error);
  
		// Handle the error based on the status code
		if (error.statusCode === 603) {
		  // Specific handling for status code 603
		} else {
		  // Handle other errors
		}
	  }
	};

	handleError = (e) => {
		console.error(e);
		alert(e.message);
	};

	cleanPhoneNumber(phoneNumber: string): string {
		// Replace any non-digit character with an empty string
		const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");
		return cleanedPhoneNumber;
	}
	initCallApp() {
		this._marketplaceService.getMarketplaceAppDetails({ app_name: "RingCentral" }).subscribe((response) => {
			this.ring_info = response;
			this.clientId = decryptData(this.ring_info?.client_id);
			this.clientSecret = decryptData(this.ring_info?.secret_key);
			this.serverURL = this.ring_info?.server_url;
			try {
				// this.jwt_token = decryptData(this.ring_info?.jwd_token);
				let userTokenList = this.ring_info?.user_token
				setTimeout(() => {
					let user_token = userTokenList?.filter((ele) => ele.profile_id === this.currentUser.profile.profile_id);
					if (user_token?.length > 0){
						this.jwt_token = decryptData(user_token[0].jwt_token);
					} else {
						this.ring_info.is_app_alive = false
					}
					this.initRingCentral();
				}, 600);
			} catch (error) {
				console.log("Error in decrypt Ring central token", error)
			}
		});
	}

	registerWebPhone(loginResponse:any, sip_data: any) {
		const remoteVideoElement = document.getElementById('remoteVideo') as HTMLMediaElement;
		const localVideoElement = document.getElementById('localVideo') as HTMLMediaElement;
		this.webPhone = new WebPhone(this.sip_data, {
			// optional
			clientId: this.clientId,
			appName: "appName",
			appVersion: "appVersion",
			uuid: loginResponse.json().endpoint_id,
			logLevel: 1, // error 0, warn 1, log: 2, debug: 3
			maxReconnectionAttempts: 0,
			audioHelper: {
				enabled: true, // enables audio feedback when web phone is ringing or making a call
				// incoming: "../../../../../assets/audio/incoming.ogg", // path to audio file for incoming call
				outgoing: "../../../../../assets/audio/outgoing.ogg", // path to aduotfile for outgoing call
			},
			media: {
				remote: remoteVideoElement, //this.remoteVideo.nativeElement,
				local: localVideoElement,
			},
			//to enable QoS Analytics Feature
			enableQos: true,
		});
		this.webPhone.userAgent.audioHelper.setVolume(0.5);
		this.webPhone.userAgent.on('disconnected', () => {
			this.webPhone.userAgent.transport.reconnectAttempts = 0;
		});
		this.webPhone.userAgent.transport.on('switchBackProxy', () => {
			// console.log('switching back to primary outbound proxy');
			this.webPhone.userAgent.transport.reconnect();
		});
		this.webPhone.userAgent.on('unregistered', () => {
			// console.log('UA Unregistered');
		  });
		this.webPhone.userAgent.on('registrationFailed', function () {
			// console.log('UA RegistrationFailed', arguments);
		  });
		return this.webPhone
	}
}
